import './home.scss';
import { on_ready,wrap_elements } from 'src/js/utils'; 

on_ready(()=>{  
    reach_out();
    init_mobile();
    window.addEventListener('resize',()=>{
        init_mobile();
    });
});

// load_swiper(()=>{ 
//     //home_banner_nav();   
// });

function init_mobile(){
    if( window.innerWidth >= 1023 ) return;
    let el = document.querySelector('.home-banner-overview .loop');
    if( el ) {
        load_swiper(()=>{
            init_swiper(el); 
        });
    }
}

function init_swiper(container){

    const items = container.querySelectorAll('.item-service-3')
    if(items.length < 2) return;

    container.classList.add('swiper');
    
    // add swiper html
    wrap_elements({
        render: container,
        wrapper_class: 'swiper-wrapper',
        items: items,
        item_class: 'swiper-slide',
    });
     

    // init swiper
    new Swiper(container, {
        autoplay : {
            delay: 0,
        },
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        speed: 5000,
        loop: true, 
    });
}

function reach_out(){
    let button = document.querySelector('.reach-out-form');
    let container = document.querySelector('.reach-out-form-container');

    button.addEventListener('click',()=>{
        if(container.dataset.control == "open"){
            container.setAttribute('data-control','close');
            button.setAttribute('data-control','close'); 
        }else{
            container.setAttribute('data-control','open'); 
            button.setAttribute('data-control','open'); 
        }

    });
}

function home_banner_nav(){

    setTimeout(() => {
        let swiper = jQuery(".home-banner .swiper" ).data("swiper");   
        swiper.params.pagination.el = ".home-banner .swiper_pagination_light";  
        swiper.params.pagination.clickable = true;  
        swiper.destroy(false);
        swiper = new Swiper(".home-banner .swiper", swiper.params);  
    }, 1500);
}
 
